import {
    Box, Heading, Text, Center, Image, VStack, HStack, Link,
    useColorModeValue, useBreakpointValue
} from "@chakra-ui/react";

export const Home = () => {
    const bgColor = useColorModeValue('white', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <div>
            <Box bg={bgColor} color={textColor}>
                <Center minH={'100vh'} >
                    <VStack spacing={6}>
                        <HStack spacing={4}>
                            <Image
                                alt={"Jelperclub-logo"}
                                src={'https://static.wixstatic.com/media/2bf3d9_8d7127c385774ffdbfe3048a6d1acd35~mv2.png/v1/fill/w_111,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/jelper-logo.png'}
                                boxSize={'150px'}
                            />
                            {isMobile ?
                                <Box>
                                    <Heading size={'4xl'} mb={2}>
                                        503
                                    </Heading>
                                    <Heading size={'xl'} mb={4}>
                                        Sorry... <br /> Under Maintenance
                                    </Heading>
                                </Box>
                                : <VStack>
                                    <Heading size={'4xl'} mb={2}>
                                        503
                                    </Heading>
                                    <Heading size={'xl'} mb={4}>
                                        Sorry... Under Maintenance
                                    </Heading>
                                </VStack>
                            }
                        </HStack>
                        {isMobile ?
                            <div>
                                <Text fontSize="xl" maxW={'620px'} textAlign="center">
                                    現在メンテンナンス中です．<br />メンテンナンスが終わり次第，サービスを再開いたします．<br />ご迷惑をおかけして申し訳ございません．
                                </Text>
                                <Text fontSize="xl" maxW={'620px'} textAlign="center">
                                    The website is currently undergoing maintenance. We will resume our services as soon as the maintenance is completed. <br /> We apologize for any inconvenience this may cause.
                                </Text>
                            </div>
                            :
                            <div>
                                <Text fontSize="2xl" maxW={'620px'} textAlign="center">
                                    現在メンテンナンス中です．メンテンナンスが終わり次第，サービスを再開いたします．<br />ご迷惑をおかけして申し訳ございません．
                                </Text>
                                <Text fontSize="2xl" maxW={'620px'} textAlign="center">
                                    The website is currently undergoing maintenance. We will resume our services as soon as the maintenance is completed. <br />We apologize for any inconvenience this may cause.
                                </Text>
                            </div>
                        }

                        <Link fontSize={'xl'} href="mailto:info@jelper.co">Mail: info@jelper.co</Link>
                    </VStack>
                </Center>
            </Box>
        </div>
    );
}
